import { route as routeFn } from 'ziggy-js'
import { RequestMethod } from './index'
import routes from './routes.json'

interface Route {
  uri: string
  methods: RequestMethod[]
  name?: string
  domain?: string
  parameters?: string[]
  bindings?: Record<string, string>
}

export type RouteName = keyof typeof routes

const currentRoute = window.Munio.config.route

export const ziggy = (window.Ziggy = {
  url: currentRoute.base.url,
  port: currentRoute.base.port,
  defaults: { ...currentRoute.defaults, ...currentRoute.params },
  routes: routes as Record<RouteName, Route>,
})

export function route(name: RouteName, params?: Record<string, string | number>, absolute = true): string {
  return routeFn(name, params, absolute)
}

window.Munio.route = routeFn

declare global {
  interface Window {
    Ziggy: {
      url: string
      port: number | null
      defaults: Record<string, string>
      routes: Record<RouteName, Route>
    }
  }
}
