import { URLSearchParams } from 'url'
import { RequestData, URLParams, RouteName, $http, route, urlParams } from '@/munio/http'
import { AxiosRequestHeaders } from 'axios'

export class Api<T = Record<string, unknown>> {
  constructor(public readonly params: T = {} as T) {}

  protected get $http() {
    return $http
  }

  protected $query(params: URLParams = window.location.search): URLSearchParams {
    return urlParams(params)
  }

  protected $route<R extends RouteName>(name: R, params?: Record<string, string | number>): string {
    return route(name, { ...this.params, ...(params || {}) })
  }

  protected $get<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.get<T>(url, data, headers, config)
  }

  protected $post<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.post<T>(url, data, headers, config)
  }

  protected $put<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.put<T>(url, data, headers, config)
  }

  protected $patch<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.patch<T>(url, data, headers, config)
  }

  protected $delete<T>(url: string, data?: RequestData, headers?: AxiosRequestHeaders, config?: object) {
    return this.$http.delete<T>(url, data, headers, config)
  }

  protected $beacon(url: string, data?: RequestData) {
    const payload = data ? new Blob([JSON.stringify(data)], { type: 'application/json' }) : null

    return navigator.sendBeacon(url, payload)
  }
}
